const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6943072', space_js: '//gflpjby.gnpad.cn/site/je/production/d_ma/static/wkft.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6943073', space_js: '//gflpjby.gnpad.cn/site/kf-enb/static/xl-gx.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6943074', space_js: '//gflpjby.gnpad.cn/source/lgfo/source/c/static/y/mhc.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6943075', space_js: '//gflpjby.gnpad.cn/production/m_h/static/gpdzn/i/e.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6943076', space_js: '//gflpjby.gnpad.cn/common/nih/static/qea/common/o-j-h.js' }];


/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Main_native: NATIVE_1,
    Classroom_native: NATIVE_2,
    Wikipedia_cat_native: NATIVE_1,
    Wikipedia_dog_native: NATIVE_2,
    Wikipedia_other_native: NATIVE_3,
    ConstellationList_banner: BANNER,
    ConstellationList_native: NATIVE_1,
    ArticleDetail_inter: INTER,
    ArticleDetail_banner: BANNER,
    ArticleDetail_native: NATIVE_1,
    PetDetail_inter: INTER,
    PetDetail_banner: BANNER,
    PetDetail_native: NATIVE_1,
    Mood_dialog: NATIVE_3,
};
